.CompanyCard {
    max-height: 250px;
    margin-bottom: 1rem;
    color: inherit;
    border-radius: 0;
    border: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.CompanyCard:hover {
    color: "#0056b3";
    text-decoration: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.CompanyCard img {
    width: 70px;
  }
